var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('b-card-title', [_vm._v(" " + _vm._s(_vm.marca.idMarca === 0 ? "Crear Marca" : "Modificar Marca"))]), _c('div', [_c('b-form-group', {
    attrs: {
      "label": "Activo",
      "label-for": "vi-activo"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-primary",
    attrs: {
      "id": "vi-activo",
      "name": "check-button",
      "switch": ""
    },
    model: {
      value: _vm.marca.estatus,
      callback: function callback($$v) {
        _vm.$set(_vm.marca, "estatus", $$v);
      },
      expression: "marca.estatus"
    }
  })], 1)], 1)], 1), _c('b-card-body', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nombre",
      "label-for": "vi-nombre"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "BriefcaseIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "vi-nombre",
      "placeholder": "Nombre de la marca"
    },
    model: {
      value: _vm.marca.nombreMarca,
      callback: function callback($$v) {
        _vm.$set(_vm.marca, "nombreMarca", $$v);
      },
      expression: "marca.nombreMarca"
    }
  })], 1)], 1)], 1), _vm.marca.idMarca !== 0 ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tiendas",
      "label-for": "vi-tienda"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "vi-tienda",
      "label": "nombre",
      "multiple": "",
      "reduce": function reduce(tienda) {
        return tienda.id;
      },
      "options": _vm.tiendas,
      "loading": _vm.loadingTiendas,
      "filterable": true
    },
    model: {
      value: _vm.marca.tiendas,
      callback: function callback($$v) {
        _vm.$set(_vm.marca, "tiendas", $$v);
      },
      expression: "marca.tiendas"
    }
  })], 1)], 1) : _vm._e(), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "block": "",
      "variant": "primary",
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.saveData
    }
  }, [_vm.loading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "label": "Loading..."
    }
  }) : _c('div', [_vm._v("Guardar")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }