<template>
  <div>
    <b-alert variant="danger" :show="error.length > 0">
      <h4 class="alert-heading">Error obteniendo datos</h4>
      <div class="alert-body">
        Ocurrió un error al obtener las Marcas {{ error }}
      </div>
    </b-alert>

    <div v-if="error.length === 0">
      <div style="text-align: center; margin: 45vh 0" v-if="loading">
        <b-spinner label="Loading..." />
      </div>

      <b-row v-else>
        <b-col cols="12" md="6">
          <b-card>
            <b-card-header class="p-0">
              <b-card-title>Marcas</b-card-title>
              <b-button @click="createBrand" variant="primary">
                Crear
              </b-button>
            </b-card-header>

            <div class="custom-search d-flex justify-content-end mt-2">
              <b-form-group>
                <div class="d-flex align-items-center">
                  <label class="mr-1">Buscar</label>
                  <b-form-input
                    v-model="searchTerm"
                    placeholder="Buscar"
                    type="text"
                    class="d-inline-block"
                  />
                </div>
              </b-form-group>
            </div>

            <!-- table -->
            <vue-good-table
              :rows="rows"
              :columns="columns"
              @on-row-click="onMarcaClick"
              :sort-options="{
                enabled: false,
              }"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm,
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
            >
              <template slot="table-row" slot-scope="props">
                <!-- Column: Estado -->
                <span v-if="props.column.field === 'estatus'">
                  <b-badge :variant="estadoVariant(props.row.estatus)">
                    {{ estadoText(props.row.estatus) }}
                  </b-badge>
                </span>
                <!-- Column: Otras Columnas -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Cantidad de marcas </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['10', '15', '20']"
                      class="mx-1"
                      @input="
                        (value) =>
                          props.perPageChanged({ currentPerPage: value })
                      "
                    />
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-card>
        </b-col>

        <b-col cols="12" md="6">
          <crear-editar
            v-if="selectedBrand !== null"
            :marca="selectedBrand"
            :refreshData="getMarcaData"
          />
          <div v-else class="iconEmpty">
            <feather-icon icon="BriefcaseIcon" size="100" />
          </div>
        </b-col>
      </b-row>
      <b-modal
        id="modal-error"
        ok-only
        ok-variant="danger"
        ok-title="Accept"
        modal-class="modal-danger"
        centered
        ref="modal-error"
        title="Error"
      >
        <b-card-text>
          {{ error }}
        </b-card-text>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BBadge,
  BAlert,
  BAvatar,
  BButton,
  BSpinner,
  BDropdown,
  BCardText,
  BFormGroup,
  BFormInput,
  BCardTitle,
  BFormSelect,
  BCardHeader,
  BPagination,
  BDropdownItem,
} from "bootstrap-vue";
import CrearEditar from "./CrearEditar.vue";
import { getAuthToken } from "@/auth/utils";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAlert,
    BBadge,
    BAvatar,
    BButton,
    BSpinner,
    BDropdown,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BCardHeader,
    BPagination,
    CrearEditar,
    VueGoodTable,
    BDropdownItem,
    ToastificationContent,
  },
  data() {
    return {
      rows: [],
      error: "",
      columns: [
        {
          label: "Nombre",
          field: "nombreMarca",
        },
        {
          label: "Estado",
          field: "estatus",
        },
      ],
      loading: true,
      searchTerm: "",
      pageLength: 10,
      selectedBrand: null,
    };
  },
  computed: {
    estadoText() {
      return (s) => (s === "A" ? "Activo" : "Inactivo");
    },
    estadoVariant() {
      return (s) => (s === "A" ? "light-success" : "light-secondary");
    },
  },
  methods: {
    createBrand() {
      this.selectedBrand = null;
      this.$nextTick(() => {
        this.selectedBrand = {
          idMarca: 0,
          estatus: false,
          tiendas: [],
          nombreMarca: "",
        };
      });
    },
    onMarcaClick(params) {
      this.selectedBrand = null;
      this.$nextTick(() => {
        const marcaAUX = JSON.parse(JSON.stringify(params.row));
        marcaAUX.estatus = params.row.estatus === "A";
        marcaAUX.tiendas = params.row.lsTiendas.map((t) => t.idEmpresaAsociada);
        this.selectedBrand = marcaAUX;
      });
    },
    getMarcaData() {
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/Marcas",
          body: JSON.stringify({
            opt: "V",
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            this.rows = res.data.lsMarcas;
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.error = e.message;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getMarcaData();
  },
};
</script>

<style lang="scss" >
table.vgt-table {
  font-size: 13px !important;
}

@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
