<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>
        {{
          marca.idMarca === 0 ? "Crear Marca" : "Modificar Marca"
        }}</b-card-title
      >
      <div>
        <b-form-group label="Activo" label-for="vi-activo">
          <b-form-checkbox
            id="vi-activo"
            v-model="marca.estatus"
            class="custom-control-primary"
            name="check-button"
            switch
          />
        </b-form-group>
      </div>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Nombre" label-for="vi-nombre">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="BriefcaseIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="vi-nombre"
                placeholder="Nombre de la marca"
                v-model="marca.nombreMarca"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12" v-if="marca.idMarca !== 0">
          <b-form-group label="Tiendas" label-for="vi-tienda">
            <v-select
              id="vi-tienda"
              label="nombre"
              multiple
              v-model="marca.tiendas"
              :reduce="(tienda) => tienda.id"
              :options="tiendas"
              :loading="loadingTiendas"
              :filterable="true"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" class="mt-2">
          <b-button
            block
            @click="saveData"
            variant="primary"
            class="mr-1"
            :disabled="loading"
          >
            <b-spinner v-if="loading" small label="Loading..." />
            <div v-else>Guardar</div>
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>


<script>
import {
  BRow,
  BCol,
  BForm,
  BCard,
  BButton,
  BSpinner,
  BCardBody,
  BCardText,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BCardHeader,
  BButtonGroup,
  BFormCheckbox,
  BFormTextarea,
  BFormRadioGroup,
  BFormSpinbutton,
  BInputGroupPrepend,
} from "bootstrap-vue";

import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { getAuthToken } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BForm,
    BButton,
    vSelect,
    BSpinner,
    BCardBody,
    flatPickr,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BCardHeader,
    BInputGroup,
    BButtonGroup,
    BFormCheckbox,
    BFormTextarea,
    BFormRadioGroup,
    BFormSpinbutton,
    BInputGroupPrepend,
    ToastificationContent,
  },
  props: {
    marca: {
      type: Object,
      required: true,
    },
    refreshData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      tiendas: [],
      loading: false,
      loadingTiendas: true,
    };
  },
  mounted() {
    this.getTiendas();
  },
  methods: {
    getTiendas() {
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/GetStores",
          body: JSON.stringify({
            all: true,
            empresa: 2,
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          this.loadingTiendas = false;
          if (res.data.bOk) {
            this.tiendas = res.data.stores;
          } else {
            this.error = res.data.mensaje;
          }
        })
        .catch((e) => {
          this.error = e.message;
          this.loadingTiendas = false;
        });
    },
    saveData(e) {
      e.preventDefault();
      this.loading = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/Marcas",
          body: JSON.stringify({
            opt: this.marca.idMarca === 0 ? "I" : "U",
            estatus: this.marca.estatus ? "A" : "I",
            IDMarca: this.marca.idMarca,
            tokenSesion: getAuthToken(),
            NombreMarca: this.marca.nombreMarca,
            LisIDEmpresaAsociada: this.marca.tiendas,
          }),
        })
        .then((res) => {
          this.loading = false;
          if (this.marca.idMarca === 0) {
              this.marca.idMarca = res.data.iDtemp;
          }
          if (res.data.bOk) {
            this.refreshData();
            setTimeout(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Éxito",
                  icon: "CheckIcon",
                  text:
                    this.marca.idMarca === 0
                      ? "Se creo el marca correctamente"
                      : "Se actualizo correctamente el marca.",
                  variant: "success",
                },
              });
            }, 500);
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "AlertTriangleIcon",
                text:
                  this.marca.idMarca === 0
                    ? `Ocurrió un error al Crear el marca (${res.data.mensaje}).`
                    : `Ocurrió un error al actualizar el marca (${res.data.mensaje}).`,
                variant: "danger",
              },
            });
          }
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              text:
                this.marca.idMarca === 0
                  ? `Ocurrio un error al Crear el marca (${e.message}).`
                  : `Ocurrio un error al actualizar el marca (${e.message}).`,
              variant: "danger",
            },
          });
          this.loading = false;
        });
    },
  },
};
</script>


<style lang="scss">
.iconEmpty {
  height: 45vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
@import "@core/scss/vue/libs/vue-select.scss";
</style>

